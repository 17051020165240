.card {
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0.25rem;
}

.card.flex-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  min-width: 0;
}

.card.flex-card > a > img {
  width: 100%;
  height: 225px;
  object-fit: cover;
}
